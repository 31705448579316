import { type neutralColors, type tagsColors } from "~/core/types";
import { Button } from "~/coreUI/Basic/Button/Button";
import { Check } from "~/coreUI/Basic/icons";
import { type FormBaseProps } from "../types";

export interface PopoverPanelListProps extends FormBaseProps {
  color?: tagsColors;
  mode?: "headless" | "dialog" | "default";
  isOpen?: boolean;
  setIsOpen?: (e: boolean) => void;
  hasPadding?: boolean;
  close?: () => void;
  searchValue?: string | null;
  size?: "sm" | "md" | "lg";
  bgColor?: neutralColors;
}

interface ComponentProps {
  close?: () => void;
}

export const MenuList = (props: PopoverPanelListProps) => {
  const {
    allOptions,
    activeIds,
    onAdd,
    onRemove,
    acceptMultiple,
    mode = "headless",
    close,
    size = "sm",
    bgColor = "default",
    searchValue,
  } = props;

  const Component = ({ close }: ComponentProps) => {
    return (
      <>
        {allOptions &&
          allOptions
            .filter((e) => {
              return searchValue
                ? e.name.toLowerCase().includes(searchValue?.toLowerCase())
                : e;
            })
            .map((option, index) => {
              const isActive = activeIds?.includes(option.id);
              return (
                <Button
                  {...option}
                  iconPosition="start"
                  icon={
                    <Check
                      className={isActive ? "colored" : "text-transparent"}
                    />
                  }
                  key={option.id}
                  label={option.name}
                  isHeadless
                  className="w-full"
                  onClick={() => {
                    isActive
                      ? onRemove
                        ? onRemove(option.id)
                        : null
                      : onAdd(option.id, option, index);
                    !acceptMultiple && close && close();
                  }}
                  bgColor={bgColor}
                  size={size}
                />
              );
            })}
      </>
    );
  };

  if (mode === "headless") {
    return <Component close={close} />;
  }

  if (mode === "default") {
    return <Component />;
  } else return null;
};
