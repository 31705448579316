import { useTheme } from "next-themes";
import { useEffect, useState } from "react";
import { Moon, Sun } from "~/coreUI/Basic/icons";
import { DropdownFill } from "~/coreUI/Basic/icons/DropdownFill";
import { updateSourceMedia } from "~/core/styles/updateSourceMedia";
import { type ButtonThemeProps, type OptionProps } from "./types";
import { Button } from "~/coreUI/Basic/Button/Button";
import { Popover } from "~/coreUI/Overlays/Popover/Popover";
import { MenuList } from "~/coreUI/Forms/MenuList/MenuList";
import { cn } from "~/core/styles/helpers";

export const ButtonTheme = (props: ButtonThemeProps) => {
  const {
    className,
    mode = "extended",
    popoverPlacement,
    popoverBgColor = "default",
    buttonBgColor = "default",
  } = props;
  const [_isModalOpen, setIsModalOpen] = useState(false);

  const { resolvedTheme, setTheme } = useTheme();
  const [themeMode, setThemeMode] = useState<OptionProps>();

  enum THEME_OPTIONS {
    system = "system",
    light = "light",
    dark = "dark",
  }

  useEffect(() => {
    const localStorageTheme = localStorage && localStorage.getItem("theme");
    if (localStorageTheme) {
      setThemeMode(options.find((option) => option.id === localStorageTheme));
    } else {
      setThemeMode(options[0]);
    }
  }, []);

  function toggleTheme() {
    const nextTheme =
      resolvedTheme === THEME_OPTIONS.light
        ? THEME_OPTIONS.dark
        : resolvedTheme === THEME_OPTIONS.dark
        ? THEME_OPTIONS.light
        : THEME_OPTIONS.system;
    setThemeMode(options.find((option) => option.id === nextTheme));
    setTheme(nextTheme);
    updateSourceMedia(nextTheme);
  }

  const options: OptionProps[] = Object.values(THEME_OPTIONS).map((option) => ({
    id: option,
    name: option,
    onClick: () => setTheme(option),
  }));

  const ButtonThemeToggler = ({ mode }: ButtonThemeProps) => {
    if (mode === "compact")
      return (
        <Button
          bgColor="soft"
          size="sm"
          onClick={() => toggleTheme()}
          className={className}
          icon={
            <>
              {resolvedTheme === "dark" ? (
                <Moon className="relative shrink-0 p-[0.75px] text-primary-light" />
              ) : (
                <Sun className="relative  shrink-0 text-primary-dark" />
              )}
            </>
          }
          aria-label="Toggle theme"
        />
      );

    if (mode === "compactNeutral") {
      return (
        <button
          aria-label="Toggle theme"
          onClick={() => toggleTheme()}
          className="rounded-full border-[1px] border-grey p-[2px]"
        >
          {resolvedTheme === "dark" ? (
            <Moon className="relative w-4 shrink-0 p-[0.75px] text-grey" />
          ) : (
            <Sun className="relative  w-4 shrink-0 text-grey" />
          )}
        </button>
      );
    } else return null;
  };

  return (
    <div className=" w-fit">
      {mode === "extended" && (
        <>
          <div>
            <div className="pb-3 text-xs text-grey">Theme :</div>
            <Popover
              placement={popoverPlacement}
              button={() => (
                <Button
                  className={cn(className)}
                  as="div"
                  label={themeMode ? themeMode.name : "no settings"}
                  icon={<DropdownFill />}
                  shapeStyle="fill"
                  size="sm"
                  status="enabled"
                  bgColor={buttonBgColor}
                />
              )}
              panel={() => (
                <MenuList
                  mode="headless"
                  allOptions={options}
                  onAdd={(_id, column) => {
                    setThemeMode(column);
                    column.onClick !== undefined && column.onClick();
                    setIsModalOpen(false);
                  }}
                  acceptMultiple={false}
                  activeIds={options
                    .filter((option) => option.id === themeMode?.id)
                    .map((option) => option.id)}
                  bgColor={popoverBgColor}
                />
              )}
              bgColor={popoverBgColor}
              popoverSize="xs"
            />{" "}
          </div>
        </>
      )}
      {(mode === "compact" || mode === "compactNeutral") && (
        <ButtonThemeToggler {...props} />
      )}
    </div>
  );
};
