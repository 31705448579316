import { type View } from "@prisma/client";
import { type ViewWithDetails } from "~/features/helpers/getViewWithDetails";

export type generateLinkProps = {
  root?: "embed" | "builder";
  view?: ViewWithDetails;
  paths?: (string | null | undefined)[];
};

export const generateLink = ({ root, view, paths }: generateLinkProps) => {
  const resolvedPaths = !paths ? [root, view?.id] : paths;
  const validPaths = resolvedPaths?.filter(
    (path) => path !== null && path !== undefined
  );

  const domainName =
    (process.env.NODE_ENV === "development"
      ? process.env.NEXT_PUBLIC_LOCALHOST_NAME
      : process.env.NEXT_PUBLIC_DOMAIN_NAME) || "";

  return (
    (validPaths &&
      `${domainName}/${
        !!paths ? [paths[0], paths[2]].join("/") : validPaths.join("/")
      }`) ||
    ""
  );
};
