import { Popover as PopoverComponent } from "@headlessui/react";
import { cva } from "class-variance-authority";
import { motion } from "framer-motion";
import { useState } from "react";
import { usePopper } from "react-popper";
import { bgStyles } from "~/core/styles/cvaStyles";

import { cn } from "~/core/styles/helpers";
import {
  type neutralColors,
  type baseColors,
  type sizes,
  type UiElement,
} from "~/core/types";

interface PopoverPanelProps extends UiElement {
  button: (open: boolean) => JSX.Element;
  panel: (close?: () => void) => JSX.Element;
  color?: baseColors;
  placement?: "auto" | "top" | "bottom" | "left";
  offset?: number;
  classNamePanel?: string;
  popoverSize?: sizes;
  bgColor?: neutralColors;
  popoverButtonOutlineColor?: "soft" | "default";
}

const panelStyles = cva(
  "border-[1px] border-grey !border-opacity-10 w-full overflow-y-auto z-50 flex flex-col rounded-md",
  {
    variants: {
      popoverSize: {
        xs: "text-xs max-w-[150px] p-2",
        sm: "text-xs max-h-[450px] p-4",
        md: "lg:max-h-[500px] max-w-[250px] text-sm p-8",
        lg: "text-sm p-16",
      },
    },
    defaultVariants: {
      popoverSize: "md",
    },
  }
);

const popoverButtonOutlineStyles = cva("rounded-full", {
  variants: {
    popoverButtonOutlineColor: {
      default: "dark:!ring-black !ring-white",
      soft: "dark:!ring-grey-dark !ring-grey-light",
    },
  },
});

export const Popover = (props: PopoverPanelProps) => {
  const {
    button,
    panel,
    placement,
    offset = 20,
    className,
    color = "primary",
    classNamePanel,
    bgColor = "soft",
    popoverButtonOutlineColor = "soft",
    ...styleProps
  } = props;

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, offset],
        },
      },
    ],
  });

  return (
    <PopoverComponent className={cn(className)}>
      <PopoverComponent.Button
        className={cn(
          "items-center ring-0 focus:outline-none focus:ring-4",
          popoverButtonOutlineStyles({
            popoverButtonOutlineColor,
          })
        )}
        ref={setReferenceElement}
        aria-label="Open menu"
      >
        {(buttonRender) => button(buttonRender.open)}
      </PopoverComponent.Button>
      <PopoverComponent.Panel
        as={motion.div}
        className={cn(
          color,
          panelStyles({
            ...styleProps,
          }),
          bgStyles({ bgColor: bgColor }),
          classNamePanel
        )}
        ref={setPopperElement}
        style={styles.popper}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        {...attributes.popper}
      >
        {(panelRender) => panel(panelRender.close)}
      </PopoverComponent.Panel>
    </PopoverComponent>
  );
};
