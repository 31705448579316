import { type VariantProps, cva } from "class-variance-authority";
import { type UiElement } from "~/core/types";

const container = cva("flex w-full flex-col justify-center items-center", {
  variants: {
    xPadding: {
      never: "px-0",
      all: "px-3 sm:px-5 md:px-12 lg:px-16 xl:px-28",
      mobileToMd: "px-3 sm:px-5 md:px-12 lg:px-0 xl:px-0",
      mobileToLg: "px-3 sm:px-5 md:px-12 lg:px-16 xl:px-0",
      desktop: "px-0 sm:px-0 md:0 lg:px-16 xl:px-28",
    },
    yPadding: {
      md: "py-4 xl:py-16 md:min-h-[calc(100vh - 6rem)]",
      lg: "py-36 md:min-h-[calc(100vh - 6rem)]",
      none: "",
    },
  },
});

const containerInner = cva("mx-auto w-full", {
  variants: {
    wide: {
      true: "",
      false: "max-w-7xl",
    },
  },
});

interface ContainerBaseProps extends UiElement {
  innerClassName?: string;
  id?: string;
  children: React.ReactNode;
}

export type ContainerProps = ContainerBaseProps &
  VariantProps<typeof container> &
  VariantProps<typeof containerInner>;

export const Container = (props: ContainerProps) => {
  const {
    xPadding = "all",
    yPadding = "none",
    wide = false,
    className,
    innerClassName,
    children,
    id,
  } = props;
  return (
    <div
      className={container({
        xPadding,
        yPadding,
        className,
      })}
      id={id}
    >
      <div
        className={containerInner({
          wide,
          className: innerClassName,
        })}
      >
        {children}
      </div>
    </div>
  );
};
